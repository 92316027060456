import React from 'react';
import { IGatsbyImageData, GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { visuallyHidden } from "@lekoarts/gatsby-theme-jodie/src/styles/utils"
import { BLUE, LIGHT_BLUE, LIGHT_GREEN, PRIMARY_GREEN } from '../../../colors';
import './landing-styles.css';
import { PREFIX } from '../../../constants';
const styles = {
  "@media screen and (min-width: 1280px)": {

  },
}


export default function Landing() {
  return (
    <div style={{
      display: 'flex',
      height: '100%',
      width: '100%',
      background: BLUE,
      flexDirection: 'row',
      justifyContent: 'center',
      gap: 40,
      alignContent: 'stretch',
    }}>
      <div id="election-hint" style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: 'white',
        // width: '100%',
        paddingRight: '2rem',
        paddingLeft: '2rem',
        // border: `0.2rem ${'white'} solid`,
      }}>
        {/* <h1 style={{ marginBottom: 0 }} id="buergermeisterwahl">
          Bürgermeisterwahl
        </h1> 
        <h4 style={{ marginTop: 0, }} id="election-date">am 12. September 2021 in Belm</h4>
        <h1 style={{ marginTop: 0, }} id="slogan">Eine starke Gemeinde – dafür stehe ich.</h1>
        <h4 style={{ marginBottom: 0 }} id="belm-places">Belm – Powe – Icker – Vehrte – Haltern</h4> */}
        <h1 style={{ marginBottom: 0 }} id="buergermeisterwahl">
          Bürgermeisterwahl
        </h1> 
        <h4 style={{ marginTop: 0, }} id="election-date">Bei allen Wählerinnen und Wählern bedanke ich mich für die erneute Wahl zum Bürgermeister und das mit dem sehr guten Ergebnis verbundene Vertrauen.</h4>
        <h1 style={{ marginTop: 0, }} id="slogan">Danke.</h1>
      </div>

      <img
        // loading={'eager'}
        src={`${PREFIX}/portrait.jpeg`}
        alt=""
        style={{
          padding: '2rem',
          objectFit: 'contain',
          height: '100%',
        }}
        id="portrait-img"
      />
    </div>
    // <StaticImage
    //       loading={'eager'}
    //       src={'../../../../static/portrait.jpeg'}
    //       alt=""
    //       style={{
    //         padding: '2rem',
    //         background: 'white',
    //         objectFit: 'fill',
    //       }}
    //     />
    // <div style={{
    //   display: 'flex',
    //   position: 'absolute',
    //   justifyContent: 'space-evenly',
    //   background: LIGHT_BLUE,
    //   flexWrap: 'wrap'
    // }}>
    //   <div style={{
    //     justifyContent: 'center',
    //     display: 'flex',
    //     alignItems: 'center',
    //     maxWidth: '50%',
    //     minWidth: '40%',
    //   }}>
    //     <div style={{
    //       background: BLUE,
    //       color: 'white',
    //       width: '100%',
    //       paddingRight: '2rem',
    //       paddingLeft: '2rem',
    //       border: `0.2rem ${'white'} solid`,
    //       // borderRadius: 20
    //     }}>
    //     <h1 sx={visuallyHidden}>
    //       Bürgermeisterwahl
    //     </h1>
    //     <h5>am 12. September 2021 in Belm</h5>
    //     <h5>Belm – Powe – Icker – Vehrte – Haltern</h5>
    //     <h2>Eine starke Gemeinde – dafür stehe ich.</h2>
    //     </div>
    //   </div>
    //   <div id="image" style={{
    //     width: '30%',
    //     maxWidth: '500px',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     justifyContent: 'center',
    //   }}>
    //     <div id="image-wrapper" style={{
    //       display: 'flex',
    //       background: 'whitesmoke',
    //       padding: '0.8rem',
    //       boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.27)',

    //     }}>
    //     <StaticImage
    //       loading={'eager'}
    //       src={'../../../../static/portrait.jpeg'}
    //       alt=""
    //       style={{
    //         padding: '2rem',
    //         background: 'white',
    //         objectFit: 'cover',
    //       }}
    //     />
    //     </div>

    //   </div>

    // </div>




    // <div style={{
    //   height: '60%',
    //   width: '100%',
    //   background: 'white',
    //   display: 'flex',
    // }}>
    //   <div className="outer-frame" style={{
    //     width: '50%',
    //     justifyContent: 'center',
    //     marginLeft: '10%',
    //     height: '100%',
    //     display: 'flex'
    //   }}>
    //     <div style={{
    //       marginTop: '25%',
    //       height: '50%',
    //       display: 'inline-table',
    //     }}>
    //     <StaticImage
    //       loading={'eager'}
    //       src={'https://viktor-hermeler.de/img/portrait.jpg'}
    //       alt=""
    //       style={{
    //       }}
    //     />
    //     </div>
    //   </div>
    //   <div style={{
    //     width: '50%',
    //     background: 'white',
    //   }}> 

    //   </div>
    // </div>

  );
}