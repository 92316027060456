const modifyGrid = (data) => {
  const persoenlichesIndex = data.findIndex((item) => item.slug === '/persoenliches');
  const inhalteIndex = data.findIndex((item) => item.slug === '/inhalte');
  const aktuellesIndex = data.findIndex((item) => item.slug === '/aktuelles');
  // const kontaktIndex = data.findIndex((item) => item.slug === '/kontakt');
  // console.log(    {...data[inhalteIndex], key: 'inhalt-landing'},
  // )
  return [
    {
      cover: {},
      key: 'landing',
      slug: '/landing',
      title: 'landing'
    },
    data[inhalteIndex],
    data[persoenlichesIndex],
    data[aktuellesIndex],
    // data[kontaktIndex],
  ]
};

export default modifyGrid;